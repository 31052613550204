import React, { useRef, useEffect } from 'react';
import * as THREE from 'three'//'https://cdnjs.cloudflare.com/ajax/libs/three.js/0.160.1/three.module.min.js';

const ParticleSphere = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x000000);
    containerRef.current.appendChild(renderer.domElement);

    // Shared particle properties
    const particleSize = 0.03;
    const particleOpacity = 0.6;
    const baseRadius = 6.4; // Base radius for the sphere
    const pulseAmount = 0.6; // How much it pulses
    const pulseSpeed = 0.003; // How fast it pulses

    // Create sphere particles
    const sphereParticleCount = 2000;
    const particles = new THREE.BufferGeometry();
    const positions = [];
    const colors = [];

    for (let i = 0; i < sphereParticleCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos((Math.random() * 2) - 1);
      
      const x = baseRadius * Math.sin(phi) * Math.cos(theta);
      const y = baseRadius * Math.sin(phi) * Math.sin(theta);
      const z = baseRadius * Math.cos(phi);
      positions.push(x, y, z);
      colors.push(1, 1, 1);
    }

    particles.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    particles.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const sphereMaterial = new THREE.PointsMaterial({
      size: particleSize,
      vertexColors: true,
      transparent: true,
      opacity: particleOpacity,
    });

    const pointCloud = new THREE.Points(particles, sphereMaterial);
    scene.add(pointCloud);

    // Create streaming particles with dispersive effect
    const createStreamParticles = (count, startPoint, endPoint, isInflow) => {
      const geometry = new THREE.BufferGeometry();
      const positions = new Float32Array(count * 3);
      const speeds = new Float32Array(count);
      const offsets = new Float32Array(count);
      const turbulence = new Float32Array(count * 3); // For random movement
      const opacities = new Float32Array(count);
      
      for (let i = 0; i < count; i++) {
        offsets[i] = (i / count) + (Math.random() * 0.2 / count);
        speeds[i] = 0.00002 + Math.random() * 0.00001;
        opacities[i] = 0;
        
        // Initial narrow stream
        const angle = (i / count) * Math.PI * 2;
        const initialSpread = isInflow ? 8.0 : 0.3;
        const radius = Math.random() * initialSpread;
        
        positions[i * 3] = startPoint.x + Math.cos(angle) * radius;
        positions[i * 3 + 1] = startPoint.y + Math.sin(angle) * radius;
        positions[i * 3 + 2] = startPoint.z + (Math.random() - 0.5) * initialSpread * 0.9;
        
        // Random movement vectors for each particle
        turbulence[i * 3] = (Math.random() - 0.5) * 0.02;
        turbulence[i * 3 + 1] = (Math.random() - 0.5) * 0.02;
        turbulence[i * 3 + 2] = (Math.random() - 0.5) * 0.02;
      }
      
      geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
      
      const material = new THREE.PointsMaterial({
        size: particleSize,
        color: 0xffffff,
        transparent: true,
        opacity: particleOpacity,
        blending: THREE.AdditiveBlending,
      });
      
      const points = new THREE.Points(geometry, material);
      points.userData = { 
        speeds, 
        offsets, 
        opacities, 
        turbulence,
        startPoint, 
        endPoint, 
        isInflow 
      };
      
      return points;
    };

    // Create streams
    const leftStream = createStreamParticles(300,  // Increased from 200
      new THREE.Vector3(-15, 0, 0),
      new THREE.Vector3(-baseRadius, 0, 0),
      true
    );
    
    const rightStream = createStreamParticles(300,
      new THREE.Vector3(baseRadius, 0, 0),
      new THREE.Vector3(15, 0, 0),
      false
    );

    scene.add(leftStream);
    scene.add(rightStream);

    // Update stream particles with dispersive effect
    const updateStreamParticles = (points, time) => {
      const positions = points.geometry.attributes.position.array;
      const { speeds, offsets, opacities, turbulence, startPoint, endPoint, isInflow } = points.userData;
      
      for (let i = 0; i < positions.length / 3; i++) {
        offsets[i] += speeds[i];
        if (offsets[i] > 1) offsets[i] = 0;
        
        const t = offsets[i];
        
        // Base position along the path with curved trajectory
        const x = startPoint.x + (endPoint.x - startPoint.x) * t;
        const baseY = startPoint.y + (endPoint.y - startPoint.y) * t;
        const baseZ = startPoint.z + (endPoint.z - startPoint.z) * t;
        
        // Calculate distance from sphere center for dispersion effect
        const distToSphere = Math.sqrt(x*x + baseY*baseY + baseZ*baseZ);
        const nearSphere = Math.abs(distToSphere - baseRadius) < 2;
        
        // Enhanced turbulence near sphere
        const turbScale = nearSphere ? 2.0 : 0.5;
        const timeScale = time * 0.001;
        
        // Gradual spread and turbulence
        const spreadFactor = isInflow ? 
          Math.max(0.2, 500.0 * (1 - t * t) + (nearSphere ? 1.0 : 0)) : // Changed from 4.0 * (1 - t)
          Math.min(500.0, t * 500);
        
        const turbX = turbulence[i * 3] * turbScale * Math.sin(timeScale + offsets[i] * Math.PI);
        const turbY = turbulence[i * 3 + 1] * turbScale * Math.cos(timeScale + offsets[i] * Math.PI);
        const turbZ = turbulence[i * 3 + 2] * turbScale * Math.sin(timeScale * 0.7 + offsets[i] * Math.PI);
        
        positions[i * 3] = x + turbX * spreadFactor;
        positions[i * 3 + 1] = baseY + turbY * spreadFactor + Math.sin(t * Math.PI * 2) * 0.5;
        positions[i * 3 + 2] = baseZ + turbZ * spreadFactor + Math.cos(t * Math.PI * 2) * 0.5;

        // Fade out near sphere
        if (isInflow) {
          opacities[i] = nearSphere ? 
            Math.max(0, 1 - (2 - Math.abs(distToSphere - baseRadius)) / 2) * particleOpacity : 
            particleOpacity;
        } else {
          opacities[i] = nearSphere ? particleOpacity : 
            Math.min(1, (distToSphere - baseRadius) / 2) * particleOpacity;
        }
      }
      
      points.geometry.attributes.position.needsUpdate = true;
      points.material.opacity = particleOpacity;
    };

    // Position camera
    camera.position.z = 10;

    // Animation
    let time = 0;
    const animate = () => {
      time += 1;
      requestAnimationFrame(animate);
      
      // Calculate pulsing radius
      const pulseFactor = Math.sin(time * pulseSpeed);
      const currentRadius = baseRadius + (pulseFactor * pulseAmount);
      
      // Update sphere particles position
      const positions = pointCloud.geometry.attributes.position.array;
      for (let i = 0; i < positions.length; i += 3) {
        const x = positions[i];
        const y = positions[i + 1];
        const z = positions[i + 2];
        
        // Get current direction vector and normalize it
        const length = Math.sqrt(x * x + y * y + z * z);
        const normalX = x / length;
        const normalY = y / length;
        const normalZ = z / length;
        
        // Apply new radius
        positions[i] = normalX * currentRadius;
        positions[i + 1] = normalY * currentRadius;
        positions[i + 2] = normalZ * currentRadius;
      }
      pointCloud.geometry.attributes.position.needsUpdate = true;
      
      // Rotate the sphere
      pointCloud.rotation.y += 0.0002;
      pointCloud.rotation.x -= 0.0001;
      pointCloud.rotation.z -= 0.0003;
      
      // Update streams
      updateStreamParticles(leftStream, time);
      updateStreamParticles(rightStream, time);
      
      renderer.render(scene, camera);
    };

    // Handle window resize
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current?.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <div 
      ref={containerRef} 
      className="fixed top-0 left-0 w-full h-screen bg-black -z-10"
      style={{ overflow: 'hidden' }}
    />
  );
};

export default ParticleSphere;