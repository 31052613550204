import React from 'react';
import DotGlobe from './Particle_globe';
// import DotGrid from './DotGrid';
import { useEffect, useRef } from 'react';

const LandingPage = () => {

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className="relative h-screen flex flex-col items-center justify-center px-4 overflow-hidden">
      <DotGlobe />
      {/* Content Layer */}
      <div class="w-full mx-auto h-screen flex flex-col justify-between text-center py-[32px] px-4 text-sm tracking-wide">
        {/* Top Text */}
      <div className="relative z-10 flex justify-between w-full mb-16 opacity-0 animate-fade-2">
        <p className="font-neue text-[#5f5f5f]">
          10x design. 10x growth. 10x impact.
        </p>
        <p className="font-neue text-[#5f5f5f]">
          1000x
        </p>
      </div>
        {/* Main Content */}
        <div className="mb-12">
          <div
            className="absolute -inset-8 z-0 pointer-events-none"
            style={{
              background: "radial-gradient(circle, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
              width: "100%", // Increase size
              height: "100%", // Increase size
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
            <h1 className="text-5xl font-neue text-gray-200 relative z-10 font-extrabold mb-1 leading-tight animate-fade-1">
              Design Intelligence <span className="font-louize font-light italic">for everyone</span>
            </h1>
            
            <p className="text-lg font-neue relative z-10 mb-8 inline-flex text-[#8f8f8f] opacity-0 animate-fade-2">
            A suite of AI agents for all your design needs.
            </p>

        </div>

        {/* Footer */}
        <p className="relative font-neue z-10 text-sm text-[#5f5f5f] max-w-lg mx-auto tracking-wide opacity-0 animate-fade-2">
          Blogs / Follow
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
